import React from 'react';
import { Link } from 'react-router-dom';
import Hero from '../components/Hero';

function Home() {
  return (
    <div>
      <Hero />
      <div id="features" className="container mx-auto p-4 mt-16">
        <h2 className="text-3xl font-bold mb-4 text-white dark:text-gray-300">Features</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-20">
          <div className="bg-gray-800 dark:bg-gray-700 text-white dark:text-gray-300 shadow-md rounded-lg p-4">
            <h3 className="text-xl font-bold mb-2">Checklists</h3>
            <p className="mb-3">Track your pulls and stay organized with our event checklists. Easily see what items you have and need, making trading at the end of an event a breeze.</p>
            <ul className="list-disc ml-5">
              <li>
                <Link to="/fasnacht" className="text-blue-400 hover:underline">
                  Fasnacht Event Checklist
                </Link>
              </li>
              <li>
                <Link to="/holiday-scorched" className="text-blue-400 hover:underline">
                  Holiday Scorched Event Checklist
                </Link>
              </li>
            </ul>
          </div>
          <div className="bg-gray-800 dark:bg-gray-700 text-white dark:text-gray-300 shadow-md rounded-lg p-4">
            <h3 className="text-xl font-bold mb-2">XP Buff Tracking</h3>
            <p className="mb-3">
            Monitor your XP buffs to maximize your experience gains in Fallout 76. Stay on top of your buffs and optimize your gameplay for better results.
            </p>
            <a href="/xp-tracker" className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
              XP Buff Tracker
              <svg className="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
              </svg>
            </a>
          </div>
          <div className="bg-gray-800 dark:bg-gray-700 text-white dark:text-gray-300 shadow-md rounded-lg p-4">
            <h3 className="text-xl font-bold mb-2">Personal Nuke Timers</h3>
            <p className="mb-3">
              Keep track of your personal nuke cooldowns for each silo in Fallout 76. Never miss an opportunity to launch a nuke and maximize your strategic advantage.
            </p>
            <a href="/nuke-timers" className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
              Nuke Timers
              <svg className="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
