import React from 'react';
import Checklist from '../components/Checklist';

const treasureHunterItems = [
  { name: "Cursed Broadsider", column: 1, dropRate: 0.0043, groupByDR: false },
  { name: "Cursed Rolling Pin", column: 1, dropRate: 0.0043, groupByDR: false },
  { name: "Cursed Sickle", column: 1, dropRate: 0.0043, groupByDR: false },
  { name: "Deathclaw Hunter Hat", column: 1, dropRate: 0.0043, groupByDR: false },
  { name: "Deathclaw Hunter Outfit", column: 1, dropRate: 0.0043, groupByDR: false },
  { name: "Garrahan Foreman Helmet", column: 1, dropRate: 0.0043, groupByDR: false },
  { name: "Garrahan Foreman Outfit", column: 1, dropRate: 0.0043, groupByDR: false },
  { name: "Grafton Monster Mask", column: 1, dropRate: 0.0043, groupByDR: false },
  { name: "Plan: Animatronic Clown", column: 1, dropRate: 0.0043, groupByDR: false },
  { name: "Plan: Balloon Arch", column: 1, dropRate: 0.0043, groupByDR: false },
  { name: "Plan: Bed of Nails", column: 1, dropRate: 0.0043, groupByDR: false },
  { name: "Plan: Bouquet of Star Balloons ", column: 1, dropRate: 0.0043, groupByDR: false },
  { name: "Plan: Camo Backpack", column: 1, dropRate: 0.0043, groupByDR: false },
  { name: "Plan: Cave Cricket Tube", column: 1, dropRate: 0.0043, groupByDR: false },
  { name: "Plan: Circus Cage Trailer", column: 1, dropRate: 0.0043, groupByDR: false },
  { name: "Plan: Fire Station Bell", column: 1, dropRate: 0.0043, groupByDR: false },
  { name: "Plan: Golf Carts", column: 1, dropRate: 0.0043, groupByDR: false },
  { name: "Plan: Grafton Monster Lamp", column: 1, dropRate: 0.0043, groupByDR: false },
  { name: "Plan: Insurgent Hat", column: 1, dropRate: 0.0043, groupByDR: false },
  { name: "Plan: Insurgent Outfit", column: 1, dropRate: 0.0043, groupByDR: false },
  { name: "Plan: Junkyard Fountain", column: 1, dropRate: 0.0043, groupByDR: false },
  { name: "Plan: Laser Gun Blue Camo Paint", column: 1, dropRate: 0.0043, groupByDR: false },
  { name: "Plan: Marine Armor Helmet", column: 2, dropRate: 0.0043, groupByDR: false },
  { name: "Plan: Missile Launcher Atom Cats Paint", column: 2, dropRate: 0.0043, groupByDR: false },
  { name: "Plan: Neon Light Diner Clock", column: 2, dropRate: 0.0043, groupByDR: false },
  { name: "Plan: Pink Sprinkles Power Armor Paints", column: 2, dropRate: 0.0043, groupByDR: false },
  { name: "Plan: Radstag Hunting Knife", column: 2, dropRate: 0.0043, groupByDR: false },
  { name: "Plan: Raw Cement Barricade", column: 2, dropRate: 0.0043, groupByDR: false },
  { name: "Plan: Safari Crocolossus Backpack", column: 2, dropRate: 0.0043, groupByDR: false },
  { name: "Plan: Safari Gorilla Backpack", column: 2, dropRate: 0.0043, groupByDR: false },
  { name: "Plan: Scorchbeast Queen Plushie", column: 2, dropRate: 0.0043, groupByDR: false },
  { name: "Plan: Scorched Tube", column: 2, dropRate: 0.0043, groupByDR: false },
  { name: "Plan: Small Supply Crate", column: 2, dropRate: 0.0043, groupByDR: false },
  { name: "Plan: Small Vault Girl Statue", column: 2, dropRate: 0.0043, groupByDR: false },
  { name: "Plan: Snallygaster Plushie", column: 2, dropRate: 0.0043, groupByDR: false },
  { name: "Plan: Strongman's Super Sledge Paint", column: 2, dropRate: 0.0043, groupByDR: false },
  { name: "Plan: Treasure Hunter Outfit", column: 2, dropRate: 0.0043, groupByDR: false },
  { name: "Plan: Treasure Hunter Outfit Hat", column: 2, dropRate: 0.0043, groupByDR: false },
  { name: "Plan: TV Aquarium", column: 2, dropRate: 0.0043, groupByDR: false },
  { name: "Plan: Vault 76 Rug", column: 2, dropRate: 0.0043, groupByDR: false },
  { name: "Plan: Wanamingo Plushie", column: 2, dropRate: 0.0043, groupByDR: false },
  { name: "Plan: WV State Bird Rug", column: 2, dropRate: 0.0043, groupByDR: false },
  { name: "Plan: Yao Guai Tube", column: 2, dropRate: 0.0043, groupByDR: false },
  { name: "Strongman Outfit", column: 2, dropRate: 0.0043, groupByDR: false },
];

// Sort by dropRate and then by name
const sortedItems = treasureHunterItems.sort((a, b) => {
  if (a.dropRate === b.dropRate) {
    return a.name.localeCompare(b.name);
  }
  return a.dropRate - b.dropRate;
});

const TreasureHunterChecklist = () => {
  return (
    <div>
      <h1 className="text-center text-2xl font-bold mt-5">Treasure Hunter Checklist</h1>
      <Checklist items={sortedItems} backupFilename="treasure_hunter_checklist_backup.json" />
    </div>
  );
};

export default TreasureHunterChecklist;
