import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'tailwindcss/tailwind.css';

const Checklist = ({ items, backupFilename = 'checklist_backup.json' }) => {
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach(checkbox => {
      const isChecked = localStorage.getItem(checkbox.id) === 'true';
      checkbox.checked = isChecked;
    });
  }, [items]);

  const saveSelection = (item) => {
    const isChecked = document.getElementById(item).checked;
    localStorage.setItem(item, isChecked);
  };

  const copyToClipboard = (text) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text).then(() => {
        showToast("Copied to clipboard!");
      }, (err) => {
        console.error('Error copying text: ', err);
        showToast("Failed to copy!", true);
      });
    } else {
      fallbackCopyTextToClipboard(text);
    }
  };

  const fallbackCopyTextToClipboard = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;

    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand('copy');
      showToast('Copied to clipboard using fallback method.');
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
      showToast("Error during fallback copying.", true);
    }

    document.body.removeChild(textArea);
  };

  const copyObtainedItems = () => {
    let obtainedItems = [];
    document.querySelectorAll('input[type="checkbox"]:checked').forEach(item => {
      obtainedItems.push(document.querySelector(`label[for="${item.id}"]`).textContent.trim());
    });
    copyToClipboard(obtainedItems.join('\n'));
  };

  const copyNeededItems = () => {
    let neededItems = [];
    document.querySelectorAll('input[type="checkbox"]:not(:checked)').forEach(item => {
      neededItems.push(document.querySelector(`label[for="${item.id}"]`).textContent.trim());
    });
    copyToClipboard(neededItems.join('\n'));
  };

  const showToast = (message, error = false) => {
    toast(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      type: error ? "error" : "success",
    });
  };

  const backupChecklist = (filename) => {
    const checklistState = [];
    document.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
      checklistState.push({ id: checkbox.id, checked: checkbox.checked });
    });
    const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(checklistState));
    const downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", filename);
    document.body.appendChild(downloadAnchorNode);
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
    showToast("Checklist backed up successfully!");
  };

  const restoreChecklist = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const checklistState = JSON.parse(e.target.result);
          checklistState.forEach(item => {
            const checkbox = document.getElementById(item.id);
            if (checkbox) {
              checkbox.checked = item.checked;
              localStorage.setItem(item.id, item.checked);
            }
          });
          showToast("Checklist restored successfully!");
        } catch (e) {
          showToast("Failed to restore checklist. The file is corrupt or invalid.", true);
        }
      };
      reader.readAsText(file);
    }
  };

  const filterItems = () => {
    const items = document.querySelectorAll('.card-body ul li label');
    if (searchValue.length >= 2) {
      items.forEach(item => {
        if (item.textContent.toLowerCase().includes(searchValue.toLowerCase())) {
          item.style.border = "2px solid yellow";
          item.style.padding = "2px";
          item.style.borderRadius = "5px";
        } else {
          item.style.border = "";
          item.style.padding = "";
          item.style.borderRadius = "";
        }
      });
    } else {
      items.forEach(item => {
        item.style.border = "";
        item.style.padding = "";
        item.style.borderRadius = "";
      });
    }
  };

  const clearSearch = () => {
    setSearchValue('');
    const items = document.querySelectorAll('.card-body ul li label');
    items.forEach(item => {
      item.style.border = "";
      item.style.padding = "";
      item.style.borderRadius = "";
    });
  };

  // Check if the list should be grouped by drop rate
  let firstColumnItems = [];
  let secondColumnItems = [];

  if (items[0]?.groupByDR) {
    const groupedItems = items.reduce((acc, item) => {
      acc[item.dropRate] = acc[item.dropRate] || [];
      acc[item.dropRate].push(item);
      return acc;
    }, {});

    const groupedItemsArray = Object.entries(groupedItems);

    firstColumnItems = groupedItemsArray.filter(([dropRate, items]) => items[0].column === 1);
    secondColumnItems = groupedItemsArray.filter(([dropRate, items]) => items[0].column === 2);
  } else {
    // If not grouping by drop rate, split items directly into two columns
    const columnOneItems = items.filter(item => item.column === 1);
    const columnTwoItems = items.filter(item => item.column === 2);

    firstColumnItems = [[null, columnOneItems]];
    secondColumnItems = [[null, columnTwoItems]];
  }

  return (
    <div className="container mx-auto p-4">
      <ToastContainer />
      <div className="mb-3 flex items-center space-x-2">
        <input 
          type="text" 
          className="form-control border border-gray-700 rounded px-3 py-2 w-full text-white focus:outline-none focus:border-blue-500 focus:shadow focus:shadow-blue-500" 
          id="searchInput" 
          placeholder="Search items..." 
          onChange={(e) => setSearchValue(e.target.value)} 
          onKeyUp={filterItems} 
          value={searchValue} 
          style={{ backgroundColor: '#1e252f' }} 
        />

        <button 
          className="btn bg-red-500 text-white rounded px-3 py-2" 
          type="button" 
          onClick={clearSearch}
        >
          Clear
        </button>
      </div>

      <div className="text-center my-4 flex flex-wrap justify-center gap-4">
        <button className="btn bg-green-500 text-white rounded px-4 py-2" onClick={copyObtainedItems}>
          Copy Obtained Items
        </button>
        <button className="btn bg-green-500 text-white rounded px-4 py-2" onClick={copyNeededItems}>
          Copy Needed Items
        </button>
        <button className="btn bg-blue-500 text-white rounded px-4 py-2" onClick={() => backupChecklist(backupFilename)}>
          Backup Checklist
        </button>
        <input type="file" id="fileInput" className="hidden" onChange={restoreChecklist} accept=".json" />
        <button className="btn bg-red-500 text-white rounded px-4 py-2" onClick={() => document.getElementById('fileInput').click()}>
          Restore Checklist
        </button>
      </div>

      <div className="centered-content" style={{ display: 'flex', justifyContent: 'center' }}>
        <div className="content-wrapper" style={{ textAlign: 'left' }}>
          <div className="card bg-gray-800 rounded-lg shadow-md p-4">
            <div className="card-body">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  {firstColumnItems.map(([dropRate, items]) => (
                    <div key={dropRate} className="p-2 mb-4">
                      {items[0].groupByDR && (
                        <h3 className="card-title text-xl font-semibold text-white mb-2">
                          Drop Rate: {dropRate}%
                        </h3>
                      )}
                      <ul className="list-disc ml-5">
                        {items.map((item) => (
                          <li key={item.name} className="flex items-center mb-1 whitespace-nowrap overflow-hidden text-ellipsis">
                            <input
                              type="checkbox"
                              id={item.name}
                              onChange={() => saveSelection(item.name)}
                              className="form-checkbox h-4 w-4 text-blue-600"
                            />
                            <label htmlFor={item.name} className="ml-2 text-white">
                              {item.name}
                            </label>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
                <div>
                  {secondColumnItems.map(([dropRate, items]) => (
                    <div key={dropRate} className="p-2 mb-4">
                      {items[0].groupByDR && (
                        <h3 className="card-title text-xl font-semibold text-white mb-2">
                          Drop Rate: {dropRate}%
                        </h3>
                      )}
                      <ul className="list-disc ml-5">
                        {items.map((item) => (
                          <li key={item.name} className="flex items-center mb-1 whitespace-nowrap overflow-hidden text-ellipsis">
                            <input
                              type="checkbox"
                              id={item.name}
                              onChange={() => saveSelection(item.name)}
                              className="form-checkbox h-4 w-4 text-blue-600"
                            />
                            <label htmlFor={item.name} className="ml-2 text-white">
                              {item.name}
                            </label>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checklist;
