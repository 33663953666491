import React from 'react';
import config from '../config';

function Footer() {
  return (
    <footer className="bg-gray-900 dark:bg-gray-800 text-white py-4">
      <div className="container mx-auto px-4 text-center">
        <p>
          FO76 Utilities made by <a href={config.empasmProfile} className="text-blue-500 hover:text-blue-700">@Empasm</a>. 
          You can find me at <a href={config.discordUrl} className="text-blue-500 hover:text-blue-700">DeluqxGamingTTV's Discord</a>
        </p>
      </div>
    </footer>
  );
}

export default Footer;
