import React from 'react';
import Checklist from '../components/Checklist';

const mothmanItems = [
  { name: "Cultist Adept Clothes", column: 1, dropRate: 0.0043, groupByDR: false },
  { name: "Cultist Adept Helmet", column: 1, dropRate: 0.0043, groupByDR: false },
  { name: "Cultist Enlightened Hood", column: 1, dropRate: 0.0043, groupByDR: false },
  { name: "Cultist Enlightened Robe", column: 1, dropRate: 0.0043, groupByDR: false },
  { name: "Cultist Eventide Hood", column: 1, dropRate: 0.0043, groupByDR: false },
  { name: "Cultist Eventide Robes", column: 1, dropRate: 0.0043, groupByDR: false },
  { name: "Cultist Incarnate Helmet", column: 1, dropRate: 0.0043, groupByDR: false },
  { name: "Cultist Neophyte Clothes", column: 1, dropRate: 0.0043, groupByDR: false },
  { name: "Cultist Neophyte Helmet", column: 1, dropRate: 0.0043, groupByDR: false },
  { name: "Plan: Bone Chill Skull Mask", column: 1, dropRate: 0.0385, groupByDR: false },
  { name: "Plan: Chainsaw Skeptikill Paint", column: 1, dropRate: 0.0385, groupByDR: false },
  { name: "Plan: Crude Skull Mask", column: 1, dropRate: 0.0385, groupByDR: false },
  { name: "Plan: Cultist Adept Hood", column: 1, dropRate: 0.0385, groupByDR: false },
  { name: "Plan: Cultist Adept Robes", column: 1, dropRate: 0.0385, groupByDR: false },
  { name: "Plan: Cultist Backpack", column: 1, dropRate: 0.0385, groupByDR: false },
  { name: "Plan: Cultist Enlightened Hood", column: 1, dropRate: 0.0385, groupByDR: false },
  { name: "Plan: Cultist Enlightened Robes", column: 1, dropRate: 0.0385, groupByDR: false },
  { name: "Plan: Cultist Eventide Hood", column: 1, dropRate: 0.0385, groupByDR: false },
  { name: "Plan: Cultist Eventide Robes", column: 1, dropRate: 0.0385, groupByDR: false },
  { name: "Plan: Cultist Incarnate Helmet", column: 1, dropRate: 0.0385, groupByDR: false },
  { name: "Plan: Cultist Neophyte Hood", column: 1, dropRate: 0.038, groupByDR: false },
  { name: "Plan: Cultist Neophyte Robes", column: 1, dropRate: 0.038, groupByDR: false },
  { name: "Plan: Dried Wildflower Bouquet", column: 1, dropRate: 0.038, groupByDR: false },
  { name: "Plan: Enlightened Lantern", column: 2, dropRate: 0.038, groupByDR: false },
  { name: "Plan: Fluttering Moths", column: 2, dropRate: 0.038, groupByDR: false },
  { name: "Plan: Fuzzy Enlightened Plushie", column: 2, dropRate: 0.038, groupByDR: false },
  { name: "Plan: Fuzzy Mothman Plushie", column: 2, dropRate: 0.038, groupByDR: false },
  { name: "Plan: Hammered Skull Mask", column: 2, dropRate: 0.038, groupByDR: false },
  { name: "Plan: Honeycomb Paper Blue Mothman", column: 2, dropRate: 0.038, groupByDR: false },
  { name: "Plan: Honeycomb Paper Brown Mothman", column: 2, dropRate: 0.038, groupByDR: false },
  { name: "Plan: Honeycomb Paper Green Mothman", column: 2, dropRate: 0.038, groupByDR: false },
  { name: "Plan: Honeycomb Paper Mothman Globe", column: 2, dropRate: 0.038, groupByDR: false },
  { name: "Plan: Honeycomb Paper Red Mothman", column: 2, dropRate: 0.038, groupByDR: false },
  { name: "Plan: Mothman Bug Zapper (Not tradeable)", column: 2, dropRate: 0.038, groupByDR: false },
  { name: "Plan: Mothman Cultist Incense", column: 2, dropRate: 0.038, groupByDR: false },
  { name: "Plan: Moths of West Virginia Display Cabinet", column: 2, dropRate: 0.038, groupByDR: false },
  { name: "Plan: Moths of West Virginia Mounting Board", column: 2, dropRate: 0.038, groupByDR: false },
  { name: "Plan: Sacred Mothman Tome", column: 2, dropRate: 0.038, groupByDR: false },
  { name: "Plan: Taxidermy American Dagger Moth", column: 2, dropRate: 0.038, groupByDR: false },
  { name: "Plan: Taxidermy Beautiful Wood Nymph Moth", column: 2, dropRate: 0.038, groupByDR: false },
  { name: "Plan: Taxidermy Giant Leopard Moth", column: 2, dropRate: 0.038, groupByDR: false },
  { name: "Plan: Taxidermy Io Moth", column: 2, dropRate: 0.038, groupByDR: false },
  { name: "Plan: Taxidermy Luna Moth", column: 2, dropRate: 0.038, groupByDR: false },
  { name: "Plan: Taxidermy Virginia Creeper Moth", column: 2, dropRate: 0.038, groupByDR: false },
  { name: "Plan: Wise Mothman Throne", column: 2, dropRate: 0.038, groupByDR: false },
  { name: "Plan: Mothman Equinox Stein", column: 2, dropRate: 100, groupByDR: false },
];

// Sort by dropRate and then by name
const sortedItems = mothmanItems.sort((a, b) => {
  if (a.dropRate === b.dropRate) {
    return a.name.localeCompare(b.name);
  }
  return a.dropRate - b.dropRate;
});

const MothmanEquinoxChecklist = () => {
  return (
    <div>
      <h1 className="text-center text-2xl font-bold mt-5">Mothman Equinox Checklist</h1>
      <Checklist items={sortedItems} backupFilename="mothman_checklist_backup.json" />
    </div>
  );
};

export default MothmanEquinoxChecklist;
