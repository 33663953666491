import React from 'react';
import Checklist from '../components/Checklist';

const scorchedHolidayItems = [
  { name: "Communist Commander Hat", column: 1, dropRate: "Unknown " },
  { name: "Communist Commander Outfit", column: 1, dropRate: "Unknown " },
  { name: "Jaguar Pant Suit", column: 1, dropRate: "Unknown " },
  { name: "Plan: .44 Blue Camo Paint", column: 1, dropRate: "Unknown " },
  { name: "Plan: Atomicset Ltd. Handbag Backpack", column: 1, dropRate: "Unknown " },
  { name: "Plan: Barbed Wire Fences", column: 1, dropRate: "Unknown " },
  { name: "Plan: Behemoth Boss Plushie", column: 1, dropRate: "Unknown " },
  { name: "Plan: Blue Camo Power Armor Paints", column: 1, dropRate: "Unknown " },
  { name: "Plan: Brahmin Plushie", column: 1, dropRate: "Unknown " },
  { name: "Plan: Condemned Notification Sign", column: 1, dropRate: "Unknown " },
  { name: "Plan: Deer Skull", column: 1, dropRate: "Unknown " },
  { name: "Plan: Giant Red Brontosaurus", column: 1, dropRate: "Unknown " },
  { name: "Plan: Honeycomb Paper Holiday Tree A", column: 1, dropRate: "Unknown " },
  { name: "Plan: Honeycomb Paper Holiday Tree B", column: 1, dropRate: "Unknown " },
  { name: "Plan: Honeycomb Paper Icy Snowflake", column: 1, dropRate: "Unknown " },
  { name: "Plan: Honeycomb Paper Jolly Target", column: 1, dropRate: "Unknown " },
  { name: "Plan: Honeycomb Paper Ribbon Bell", column: 1, dropRate: "Unknown " },
  { name: "Plan: Honeycomb Paper Snowman", column: 1, dropRate: "Unknown " },
  { name: "Plan: Honeycomb Paper Standing Santa", column: 1, dropRate: "Unknown " },
  { name: "Plan: Jitterbug Judy Outfit", column: 1, dropRate: "Unknown " },
  { name: "Plan: Molerat Wind Chime", column: 1, dropRate: "Unknown " },
  { name: "Plan: Mounted Antlers", column: 1, dropRate: "Unknown " },
  { name: "Plan: Mounted Beaver", column: 1, dropRate: "Unknown " },
  { name: "Plan: Mounted Brahmin Heads", column: 1, dropRate: "Unknown " },
  { name: "Plan: Mounted Cat Head", column: 1, dropRate: "Unknown " },
  { name: "Plan: Mounted Deathclaw Head", column: 1, dropRate: "Unknown " },
  { name: "Plan: Mounted Deer Head", column: 1, dropRate: "Unknown " },
  { name: "Plan: Mounted Dog Head", column: 1, dropRate: "Unknown " },
  { name: "Plan: Mounted Feral Ghoul Head", column: 1, dropRate: "Unknown " },
  { name: "Plan: Mounted Fox", column: 1, dropRate: "Unknown " },
  { name: "Plan: Mounted Frog", column: 1, dropRate: "Unknown " },
  { name: "Plan: Mounted Glowing One Head", column: 1, dropRate: "Unknown " },
  { name: "Plan: Mounted Gorilla Head", column: 1, dropRate: "Unknown " },
  { name: "Plan: Mounted Honey Beast", column: 1, dropRate: "Unknown " },
  { name: "Plan: Mounted Megasloth", column: 1, dropRate: "Unknown " },
  { name: "Plan: Mounted Mirelurk Claw", column: 1, dropRate: "Unknown " },
  { name: "Plan: Mounted Mirelurk Hunter Head", column: 1, dropRate: "Unknown " },
  { name: "Plan: Mounted Mirelurk King Head", column: 1, dropRate: "Unknown " },
  { name: "Plan: Mounted Molerat", column: 2, dropRate: "Unknown" },
  { name: "Plan: Mounted Mongrel Head", column: 2, dropRate: "Unknown" },
  { name: "Plan: Mounted Mothman", column: 2, dropRate: "Unknown" },
  { name: "Plan: Mounted Mutant Hound Head", column: 2, dropRate: "Unknown" },
  { name: "Plan: Mounted Opossum", column: 2, dropRate: "Unknown" },
  { name: "Plan: Mounted Radscorpion", column: 2, dropRate: "Unknown" },
  { name: "Plan: Mounted Radtoad", column: 2, dropRate: "Unknown" },
  { name: "Plan: Mounted Scorchbeast", column: 2, dropRate: "Unknown" },
  { name: "Plan: Mounted Snallygaster", column: 2, dropRate: "Unknown" },
  { name: "Plan: Mounted Squirrel", column: 2, dropRate: "Unknown" },
  { name: "Plan: Musket Stack Monument", column: 2, dropRate: "Unknown" },
  { name: "Plan: Nuka Girl Area Rug", column: 2, dropRate: "Unknown" },
  { name: "Plan: Peppermint Backpack Flair", column: 2, dropRate: "Unknown" },
  { name: "Plan: Plastiform Candle", column: 2, dropRate: "Unknown" },
  { name: "Plan: Plastiform Gingerbread", column: 2, dropRate: "Unknown" },
  { name: "Plan: Plastiform Nutcracker", column: 2, dropRate: "Unknown" },
  { name: "Plan: Plastiform Santa Sleigh", column: 2, dropRate: "Unknown" },
  { name: "Plan: Plastiform Santa", column: 2, dropRate: "Unknown" },
  { name: "Plan: Plastiform Snowman", column: 2, dropRate: "Unknown" },
  { name: "Plan: Plastiform Vaultboy Elf", column: 2, dropRate: "Unknown" },
  { name: "Plan: Princess Castle Pink Sleeping Bag", column: 2, dropRate: "Unknown" },
  { name: "Plan: Red Rocket Gas Pump Wall Light", column: 2, dropRate: "Unknown" },
  { name: "Plan: Red Rocket Gas Station Lamp", column: 2, dropRate: "Unknown" },
  { name: "Plan: Red Truck Ceiling Light", column: 2, dropRate: "Unknown" },
  { name: "Plan: Santa Crash", column: 2, dropRate: "Unknown" },
  { name: "Plan: Sitting Gorilla", column: 2, dropRate: "Unknown" },
  { name: "Plan: Snowman Head Backpack Flair", column: 2, dropRate: "Unknown" },
  { name: "Plan: Standing Gorilla", column: 2, dropRate: "Unknown" },
  { name: "Plan: Star Light", column: 2, dropRate: "Unknown" },
  { name: "Plan: Straw Goat", column: 2, dropRate: "Unknown" },
  { name: "Plan: Tattered Curtains", column: 2, dropRate: "Unknown" },
  { name: "Plan: Vault 51 Jumpsuit", column: 2, dropRate: "Unknown" },
  { name: "Plan: Vintage Water Cooler", column: 2, dropRate: "Unknown" },
  { name: "Plan: Wall Mounted Fan", column: 2, dropRate: "Unknown" },
  { name: "Plan: Winter Icicle Lights", column: 2, dropRate: "Unknown" },
  { name: "Tulip Sky Blue Pant Suit", column: 2, dropRate: "Unknown" },
];


// Sort by dropRate and then by name
const sortedItems = scorchedHolidayItems.sort((a, b) => {
  if (a.dropRate === b.dropRate) {
    return a.name.localeCompare(b.name);
  }
  return a.dropRate - b.dropRate;
});

const HolidayScorchedChecklist = () => {
  return (
    <div>
      <h1 className="text-center text-2xl font-bold mt-5">Holiday Scorched Event Checklist</h1>
      <Checklist items={sortedItems} backupFilename="holiday_scorched_checklist_backup.json" />
    </div>
  );
};

export default HolidayScorchedChecklist;
