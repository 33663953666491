import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import FasnachtChecklist from './pages/FashnachtChecklist';
import HolidayScorchedChecklist from './pages/HolidayScorchedChecklist';
import NukeTimers from './pages/NukeTimers';
import XPBuffTimers from './pages/XPBuffTimers';
import ComingSoon from './pages/ComingSoon';
import MothmanEquinoxChecklist from './pages/MothmanEquinoxChecklist';
import TreasureHunterChecklist from './pages/TreasureHunterChecklist';
import ModBoxChecklist from './pages/ModBoxChecklist';

function App() {
  useEffect(() => {
    const darkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    if (darkMode) {
      document.documentElement.classList.add('dark');
    }
  }, []);

  return (
    <Router>
      <Navigation />
      <div className="bg-gray-900 dark:bg-gray-900 text-white min-h-screen pt-20 md:pt-16">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/nuke-timers" element={<NukeTimers />} />
          <Route path="/xp-tracker" element={<ComingSoon />} />
          <Route path="/xp-buff-timers" element={<XPBuffTimers />} />
          <Route path="/fasnacht" element={<FasnachtChecklist />} />
          <Route path="/holiday-scorched" element={<HolidayScorchedChecklist />} />
          <Route path="/mothman-equinox" element={<MothmanEquinoxChecklist />} />
          <Route path="/treasure-hunter" element={<TreasureHunterChecklist />} />

          <Route path="/mod-box-checklist" element={<ModBoxChecklist />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
