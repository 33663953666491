import React from 'react';

function Hero() {
  return (
    <div 
      className="bg-cover bg-center h-full md:h-screen text-white dark:bg-gray-900" 
      style={{ backgroundImage: `url('/hero.webp')`, backgroundSize: 'cover' }}
    >
      <div className="container mx-auto h-full flex flex-col justify-center items-center text-center py-16 md:py-0">
        <div className="bg-black bg-opacity-50 p-8 rounded-lg">
          <h1 className="text-5xl font-bold mb-4">Welcome to FO76 Utilities</h1>
          <p className="text-xl mb-8">Your resource for Fallout 76 helpers and trackers</p>
          <a href="#features" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
            Explore Features
          </a>
        </div>
      </div>
    </div>
  );
}

export default Hero;
