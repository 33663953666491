import React from 'react';
import ModBoxChecklistComponent from '../components/ModBoxChecklistComponent';

const oneStarModBoxes = [
  { prefix: "Acrobat's", description: "-50% Fall Damage", component: "Marsupial Serum", componentQuantity: 1, legendaryModule: 60 },
  { prefix: "Adamantium", description: "Receive 15% less Limb Damage", component: "Twisted Muscles Serum", componentQuantity: 1, legendaryModule: 60 },
  { prefix: "Agility (Armour)", description: "1 Agility", component: "Agility Bobblehead", componentQuantity: 1, legendaryModule: 30 },
  { prefix: "Agility (Weapon)", description: "+3 Agility", component: "Agility Bobblehead", componentQuantity: 1, legendaryModule: 60 },
  { prefix: "Anti-Armor", description: "+50% Armor Penetration", component: "Black Titanium", componentQuantity: 5, legendaryModule: 15 },
  { prefix: "Antiseptic", description: "25% Reduced Disease Chance From Environmental Hazards", component: "Disease Cure", componentQuantity: 5, legendaryModule: 30 },
  { prefix: "Aristocrat's", description: "Grants up to +20 Energy Resistance and Damage Resistance, the higher Your Caps", component: "Caps Bobblehead", componentQuantity: 1, legendaryModule: 15 },
  { prefix: "Arms Keeper's", description: "Weapon Weights Reduced by 20%", component: "Small Guns Bobblehead", componentQuantity: 1, legendaryModule: 60 },
  { prefix: "Assassin's", description: "-15% Damage from Humans", component: "Liquid Courage", componentQuantity: 1, legendaryModule: 15 },
  { prefix: "Auto Stim", description: "Automatically use a Stimpak When Hit While Health is 25% or less, once every 60 seconds", component: "Stimpak", componentQuantity: 5, legendaryModule: 15 },
  { prefix: "Basher's", description: "+50% Bash Damage", component: "Concrete", componentQuantity: 15, legendaryModule: 30 },
  { prefix: "Belted", description: "Ammo Weight Reduced by 20%", component: "Lead", componentQuantity: 25, legendaryModule: 60 },
  { prefix: "Berserker's", description: "Damage Increases up to +50% as Damage Resistance Decreases", component: "Psycho", componentQuantity: 5, legendaryModule: 15 },
  { prefix: "Bloodied", description: "Damage Increases up to 95% as Health Decreases", component: "Adrenal Reaction Serum", componentQuantity: 1, legendaryModule: 15 },
  { prefix: "Bolstering", description: "Grants up to +35 Energy Resistance and Damage Resistance, the Lower Your Health", component: "Med X", componentQuantity: 5, legendaryModule: 15 },
  { prefix: "Burning", description: "5% chance to deal 19 Fire damage per second for 3 seconds to Melee Attackers", component: "Floater Pus Sac Flamer", componentQuantity: 15, legendaryModule: 60 },
  { prefix: "Cavalier's (Armour)", description: "75% Chance to Reduce Damage by 15% While Sprinting", component: "Oil", componentQuantity: 10, legendaryModule: 60 },
  { prefix: "Cavalier's (Weapon)", description: "-15% Damage Taken While Blocking", component: "Oil", componentQuantity: 10, legendaryModule: 60 },
  { prefix: "Chameleon", description: "Become Invisible While Sneaking and Not Moving", component: "Chameleon Serum", componentQuantity: 1, legendaryModule: 15 },
  { prefix: "Charisma (Armour)", description: "1 Charisma", component: "Charisma Bobblehead", componentQuantity: 1, legendaryModule: 30 },
  { prefix: "Cloaking", description: "Being Hit in Melee causes the Player to become Invisible Once Every 30 Seconds", component: "Stealth Boy", componentQuantity: 1, legendaryModule: 15 },
  { prefix: "Crippling", description: "+50% Limb Damage", component: "Black Titanium", componentQuantity: 5, legendaryModule: 30 },
  { prefix: "Defender's (Armour)", description: "-15% Damage Taken While Blocking", component: "Steel", componentQuantity: 25, legendaryModule: 60 },
  { prefix: "Defender's (Weapon)", description: "-40% Damage Taken While Power Attacking", component: "Steel", componentQuantity: 25, legendaryModule: 60 },
  { prefix: "Dissipating", description: "+0.25% Radiation Damage Recovery", component: "RadAway", componentQuantity: 10, legendaryModule: 60 },
  { prefix: "Diver's", description: "Breathe Underwater", component: "Plastic", componentQuantity: 15, legendaryModule: 60 },
  { prefix: "Doctor's", description: "+5% Effectiveness of Stimpaks, RadAway, and Rad-X", component: "Stimpak: Super", componentQuantity: 1, legendaryModule: 60 },
  { prefix: "Durability (Armour)", description: "Breaks 50% slower", component: "Repair Bobblehead", componentQuantity: 1, legendaryModule: 60 },
  { prefix: "Durability (Weapon)", description: "Weapons break 50% slower", component: "Repair Bobblehead", componentQuantity: 1, legendaryModule: 60 },
  { prefix: "Electrified", description: "5% chance to deal 18 Energy damage per second for 3 seconds to Melee Attackers", component: "Electrically Charged Serum", componentQuantity: 1, legendaryModule: 60 },
  { prefix: "Endurance (Armour)", description: "1 Endurance", component: "Endurance Bobblehead", componentQuantity: 1, legendaryModule: 30 },
  { prefix: "Executioner's", description: "+50% More Damage when Your Target is Below 40% Health", component: "Leather", componentQuantity: 25, legendaryModule: 15 },
  { prefix: "Explosive", description: "Bullets Explode for 20% Weapon Damage", component: "Explosive Bobblehead", componentQuantity: 1, legendaryModule: 30 },
  { prefix: "Explosive (Shotgun)", description: "Bullets Explode for 3% Weapon Damage", component: "Explosive Bobblehead", componentQuantity: 1, legendaryModule: 30 },
  { prefix: "Exterminator's (A)", description: "-15% Damage from Mirelurks and Insects", component: "Bloodbug Proboscis & Stingwing Barb", componentQuantity: 1, legendaryModule: 15 },
  { prefix: "Exterminator's (W)", description: "+50% Damage to Insects +50% Damage to Mirelurks", component: "Bloodbug Proboscis & Stingwing Barb", componentQuantity: 1, legendaryModule: 15 },
  { prefix: "Fireproof", description: "25 Fire Resistance", component: "Floater Pus Sac Flamer", componentQuantity: 10, legendaryModule: 30 },
  { prefix: "Frozen", description: "5% chance to deal 12 Cryo damage per second for 4 seconds to Melee Attackers", component: "Floater Pus Sac Freezer", componentQuantity: 15, legendaryModule: 60 },
  { prefix: "Furious", description: "+5% Damage after Each Consecutive Hit on the Same Target, up to +45%", component: "Fury", componentQuantity: 1, legendaryModule: 15 },
  { prefix: "Ghost's", description: "10% Chance to cause the Player to be Invisible for 2 seconds when Hitting a Target", component: "Stealth Boy", componentQuantity: 1, legendaryModule: 60 },
  { prefix: "Ghoul Slayer's (A)", description: "-15% Damage from Ghouls", component: "Radshield", componentQuantity: 1, legendaryModule: 15 },
  { prefix: "Ghoul Slayer's (W)", description: "+50% Damage to Ghouls", component: "Radshield", componentQuantity: 1, legendaryModule: 15 },
  { prefix: "Glutton", description: "Hunger and Thirst Grow 10% Slower", component: "Perfect Bubblegum", componentQuantity: 1, legendaryModule: 30 },
  { prefix: "Gourmand's", description: "Damage Increases up to 24% as you Fill Your Hunger and Thirst Meters", component: "Vegetarian Ham", componentQuantity: 1, legendaryModule: 15 },
  { prefix: "Hardy", description: "Receive 7% Less Explosion Damage", component: "Explosive Bobblehead", componentQuantity: 1, legendaryModule: 30 },
  { prefix: "Hazmat", description: "25 Radiation Resistance", component: "Rad X", componentQuantity: 10, legendaryModule: 30 },
  { prefix: "Heavy Hitter's", description: "+40% Power Attack Damage", component: "Concrete", componentQuantity: 25, legendaryModule: 30 },
  { prefix: "Hitman's", description: "+25% Damage While Aiming", component: "Fiber Optics", componentQuantity: 10, legendaryModule: 30 },
  { prefix: "Hunter's (A)", description: "-15% Damage From Animals", component: "Yao Guai Hide", componentQuantity: 1, legendaryModule: 15 },
  { prefix: "Hunter's (W)", description: "+50% Damage to Animals", component: "Yao Guai Hide", componentQuantity: 1, legendaryModule: 15 },
  { prefix: "Inertial", description: "Replenish 15 Action Points with each kill", component: "Canned Coffee", componentQuantity: 10, legendaryModule: 30 },
  { prefix: "Instigating", description: "+100% Damage Against Targets with Full Health", component: "Whiskey", componentQuantity: 5, legendaryModule: 15 },
  { prefix: "Intelligence (Armour)", description: "1 Intelligence", component: "Intelligence Bobblehead", componentQuantity: 1, legendaryModule: 30 },
  { prefix: "Juggernaut's", description: "Damage Increases up to 25% as Health Increases", component: "Buffout", componentQuantity: 5, legendaryModule: 15 },
  { prefix: "Junkie's", description: "Damage Increases per Addiction up to +50%", component: "Mentats", componentQuantity: 5, legendaryModule: 15 },
  { prefix: "Last Shot", description: "The last round in a magazine has a 25% chance to Deal +100% Damage", component: "Gunpowder", componentQuantity: 15, legendaryModule: 30 },
  { prefix: "Life Saving", description: "When Incapacitated, Gain a 50% Chance to Revive Yourself with a Stimpak, Once Every 60 seconds", component: "Stimpak", componentQuantity: 5, legendaryModule: 15 },
  { prefix: "Lightweight", description: "-90% Weight", component: "Cork", componentQuantity: 10, legendaryModule: 60 },
  { prefix: "Luck (Armour)", description: "1 Luck", component: "Luck Bobblehead", componentQuantity: 1, legendaryModule: 30 },
  { prefix: "Lucky", description: "+15 Bonus V.A.T.S. Critical Charge", component: "Luck Bobblehead", componentQuantity: 1, legendaryModule: 60 },
  { prefix: "Medic's", description: "Shots heal friendly target for 5% HP", component: "Medic's Bobblehead", componentQuantity: 1, legendaryModule: 15 },
  { prefix: "Mutant Slayer's", description: "+50% Damage to Super Mutants", component: "Super Mutant Head", componentQuantity: 1, legendaryModule: 15 },
  { prefix: "Mutant's (A)", description: "+10 Damage Resistance and Energy Resistance While Mutated", component: "Asbestos", componentQuantity: 10, legendaryModule: 15 },
  { prefix: "Mutant's (W)", description: "Damage Increases up to +25% as you gain Mutations", component: "Asbestos", componentQuantity: 10, legendaryModule: 15 },
  { prefix: "Nimble", description: "+100% Faster Movement Speed While Aiming", component: "Springs", componentQuantity: 25, legendaryModule: 60 },
  { prefix: "Nocturnal", description: "+40 Damage Resistance and Energy Resistance at Night", component: "Nuclear Material", componentQuantity: 10, legendaryModule: 15 },
  { prefix: "Overeater's", description: "Increases Damage Reduction up to 6% as you Fill Your Hunger and Thirst Meters", component: "Perfect Bubblegum", componentQuantity: 1, legendaryModule: 15 },
  { prefix: "Pack Rat's", description: "Junk Item Weights Reduced by 20%", component: "Marsupial Serum", componentQuantity: 1, legendaryModule: 60 },
  { prefix: "Perception (Armour)", description: "1 Perception", component: "Perception Bobblehead", componentQuantity: 1, legendaryModule: 30 },
  { prefix: "Perception (Weapon)", description: "+3 Perception", component: "Perception Bobblehead", componentQuantity: 1, legendaryModule: 60 },
  { prefix: "Poisoner's", description: "25 Poison Resistance", component: "Floater Pus Sac Gnasher", componentQuantity: 10, legendaryModule: 30 },
  { prefix: "Powered", description: "5% Action Point Regen", component: "Canned Coffee", componentQuantity: 10, legendaryModule: 30 },
  { prefix: "Quad", description: "+300% Ammo Capacity", component: "Fusion Core", componentQuantity: 1, legendaryModule: 15 },
  { prefix: "Rapid (Melee)", description: "+40% Weapon Speed", component: "Speed Demon Serum", componentQuantity: 1, legendaryModule: 30 },
  { prefix: "Rapid (Ranged/Heavy)", description: "+25% Weapon Speed", component: "Speed Demon Serum", componentQuantity: 1, legendaryModule: 30 },
  { prefix: "Regenerating", description: "+0.5% Heal Rate", component: "Healing Factor Serum", componentQuantity: 1, legendaryModule: 15 },
  { prefix: "Resilient", description: "+250 Damage Resistance While Reloading", component: "Med X", componentQuantity: 5, legendaryModule: 60 },
  { prefix: "Riposting", description: "+50% Melee Damage Reflection While Blocking", component: "Glass", componentQuantity: 25, legendaryModule: 30 },
  { prefix: "Safecracker's", description: "Increases Size of Sweet-Spot While Picking Locks by 2", component: "Lock Picking Bobblehead", componentQuantity: 1, legendaryModule: 60 },
  { prefix: "Secret Agent's", description: "+25% Less Noise While Sneaking +25% Reduce Detection Chance", component: "Sneak Bobblehead", componentQuantity: 1, legendaryModule: 60 },
  { prefix: "Sentinel's", description: "75% Chance to Reduce Damage by 15% While Not Moving", component: "Scaly Skin Serum", componentQuantity: 1, legendaryModule: 60 },
  { prefix: "Stalker's", description: "If Not In Combat, +100% V.A.T.S. Accuracy at +50% Action Points cost", component: "Calmex", componentQuantity: 1, legendaryModule: 15 },
  { prefix: "Steady", description: "+25% Melee Damage While Not Moving", component: "Orange Mentats", componentQuantity: 3, legendaryModule: 30 },
  { prefix: "Steadfast", description: "+50 Damage Resistance While Aiming", component: "Ballistic Fiber", componentQuantity: 5, legendaryModule: 60 },
  { prefix: "Strength", description: "1 Strength", component: "Strength Bobblehead", componentQuantity: 1, legendaryModule: 30 },
  { prefix: "Suppressor's", description: "Reduce Your Target's Damage Output by 25% for 5 seconds", component: "Grounded Serum", componentQuantity: 1, legendaryModule: 15 },
  { prefix: "Swift", description: "+15% Reload Speed", component: "Speed Demon", componentQuantity: 1, legendaryModule: 60 },
  { prefix: "Thru-hiker's", description: "Food, Drink, and Chem Weights Reduced by 20%", component: "Purified Water", componentQuantity: 10, legendaryModule: 60 },
  { prefix: "Toxic", description: "5% chance to deal 12 Poison damage per second for 7 seconds to Melee Attackers", component: "Floater Pus Sac Gnasher", componentQuantity: 15, legendaryModule: 60 },
  { prefix: "Troubleshooter's", description: "+50% Damage to Robots", component: "Circuitry", componentQuantity: 10, legendaryModule: 15 },
  { prefix: "Two Shot", description: "+1 Projectiles +25% Damage -150% Hip-Fire Accuracy +100% Recoil", component: "Lead", componentQuantity: 20, legendaryModule: 15 },
  { prefix: "Unyielding", description: "Gain up to +3 to All S.P.E.C.I.A.L. Stats (except END) when Health is Low", component: "X Cell", componentQuantity: 5, legendaryModule: 15 },
  { prefix: "V.A.T.S Optimized", description: "-25% Action Point Cost", component: "Sugar", componentQuantity: 10, legendaryModule: 60 },
  { prefix: "V.A.T.S. Enhanced", description: "+50% Chance to Hit a Target in V.A.T.S.", component: "Berry Mentats", componentQuantity: 3, legendaryModule: 30 },
  { prefix: "Vampire's", description: "Restore 2% Health over 2 seconds when you Hit a Target", component: "Bloodpack", componentQuantity: 10, legendaryModule: 15 },
  { prefix: "Vanguard's", description: "Grants up to +35 Energy Resistance and Damage Resistance, the higher Your Health", component: "Bloodpack", componentQuantity: 5, legendaryModule: 15 },
  { prefix: "Vital", description: "+50% Critical Damage", component: "Eagle Eyes Serum", componentQuantity: 1, legendaryModule: 30 },
  { prefix: "Warming", description: "25 Cryo Resistance", component: "Floater Pus Sac Freezer", componentQuantity: 10, legendaryModule: 30 },
  { prefix: "Weightless", description: "-90% Weight", component: "Bird Bones Serum", componentQuantity: 1, legendaryModule: 15 },
  { prefix: "Zealot's", description: "+50% Damage to Scorched", component: "Ultracite", componentQuantity: 10, legendaryModule: 15 },
];

const ModBoxChecklist = () => {
  const modBoxes = [...oneStarModBoxes];

  return (
    <div>
      <h1 className="text-center text-2xl font-bold mt-5">Mod Box Checklist</h1>
      <ModBoxChecklistComponent modBoxes={modBoxes} backupFilename="mod_box_checklist_backup.json" />
    </div>
  );
};

export default ModBoxChecklist;
