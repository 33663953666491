import React from 'react';

function About() {
  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-4 text-white dark:text-gray-300">About Us</h1>
      <p className="text-gray-400 dark:text-gray-500 mb-4">
        Deluqx Gaming is dedicated to providing the best streaming experience. Our team of experts
        is committed to helping you enjoy your favorite games and interact with a vibrant community.
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="bg-gray-800 dark:bg-gray-700 text-white dark:text-gray-300 shadow-md rounded-lg p-4">
          <h3 className="text-xl font-bold mb-2">Our Mission</h3>
          <p>To empower gamers through interactive streaming and community engagement.</p>
        </div>
        <div className="bg-gray-800 dark:bg-gray-700 text-white dark:text-gray-300 shadow-md rounded-lg p-4">
          <h3 className="text-xl font-bold mb-2">Our Vision</h3>
          <p>To be the leading platform for gaming entertainment and community events.</p>
        </div>
      </div>
    </div>
  );
}

export default About;
