import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'tailwindcss/tailwind.css';

const ModBoxChecklistComponent = ({ modBoxes, backupFilename = 'checklist_backup.json' }) => {
  const [searchValue, setSearchValue] = useState('');
  const [popupItem, setPopupItem] = useState(null); // Store the item for which the popup is open

  const togglePopup = (item) => {
    if (popupItem === item) {
      setPopupItem(null); // Close the popup if the same item is clicked again
    } else {
      setPopupItem(item); // Open the popup for the clicked item
    }
  };

  useEffect(() => {
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach(checkbox => {
      const isChecked = localStorage.getItem(checkbox.id) === 'true';
      checkbox.checked = isChecked;
    });
  }, [modBoxes]);

  const saveSelection = (item) => {
    const isChecked = document.getElementById(item).checked;
    localStorage.setItem(item, isChecked);
  };

  const copyToClipboard = (text) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text).then(() => {
        showToast("Copied to clipboard!");
      }, (err) => {
        console.error('Error copying text: ', err);
        showToast("Failed to copy!", true);
      });
    }
  };

  const copyObtainedItems = () => {
    let obtainedItems = [];
    document.querySelectorAll('input[type="checkbox"]:checked').forEach(item => {
      const label = document.querySelector(`label[for="${item.id}"]`);
      obtainedItems.push(label ? label.textContent.trim() : item.id);
    });
    copyToClipboard(obtainedItems.join('\n'));
  };

  const copyNeededItems = () => {
    let neededItems = [];
    document.querySelectorAll('input[type="checkbox"]:not(:checked)').forEach(item => {
      const label = document.querySelector(`label[for="${item.id}"]`);
      neededItems.push(label ? label.textContent.trim() : item.id);
    });
    copyToClipboard(neededItems.join('\n'));
  };

  const backupChecklist = (filename) => {
    const checklistState = [];
    document.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
      checklistState.push({ id: checkbox.id, checked: checkbox.checked });
    });
    const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(checklistState));
    const downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", filename);
    document.body.appendChild(downloadAnchorNode);
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
    showToast("Checklist backed up successfully!");
  };

  const restoreChecklist = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const checklistState = JSON.parse(e.target.result);
          checklistState.forEach(item => {
            const checkbox = document.getElementById(item.id);
            if (checkbox) {
              checkbox.checked = item.checked;
              localStorage.setItem(item.id, item.checked);
            }
          });
          showToast("Checklist restored successfully!");
        } catch (e) {
          showToast("Failed to restore checklist. The file is corrupt or invalid.", true);
        }
      };
      reader.readAsText(file);
    }
  };

  const showToast = (message, error = false) => {
    toast(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      type: error ? "error" : "success",
    });
  };

  const filterItems = (modBoxes) => {
    return modBoxes.filter((box) =>
      box.prefix?.toLowerCase().includes(searchValue.toLowerCase()) ||
      box.major?.toLowerCase().includes(searchValue.toLowerCase()) ||
      box.minor?.toLowerCase().includes(searchValue.toLowerCase())
    );
  };

  return (
    <div className="container mx-auto p-4">
      <ToastContainer />
      <div className="mb-3 flex items-center space-x-2">
        <input
          type="text"
          className="form-control border border-gray-700 rounded px-3 py-2 w-full text-white focus:outline-none focus:border-blue-500 focus:shadow focus:shadow-blue-500"
          id="searchInput"
          placeholder="Search mod boxes..."
          onChange={(e) => setSearchValue(e.target.value)}
          value={searchValue}
          style={{ backgroundColor: '#1e252f' }}
        />
      </div>

      <div className="text-center my-4 flex flex-wrap justify-center gap-4">
        <button
          className="btn bg-green-500 text-white rounded px-4 py-2"
          onClick={copyObtainedItems}
        >
          Copy Obtained Items
        </button>
        <button
          className="btn bg-green-500 text-white rounded px-4 py-2"
          onClick={copyNeededItems}
        >
          Copy Needed Items
        </button>
        <button
          className="btn bg-blue-500 text-white rounded px-4 py-2"
          onClick={() => backupChecklist(backupFilename)}
        >
          Backup Checklist
        </button>
        <input type="file" id="fileInput" className="hidden" onChange={restoreChecklist} accept=".json" />
        <button
          className="btn bg-red-500 text-white rounded px-4 py-2"
          onClick={() => document.getElementById('fileInput').click()}
        >
          Restore Checklist
        </button>
      </div>

      <div className="centered-content">
        <div className="overflow-x-auto">
          <table className="min-w-full bg-gray-800 text-white rounded-lg shadow-md table-auto">
            <thead>
              <tr>
                <th className="py-3 px-2 text-center"></th>
                <th className="py-3 px-2 text-left">Name</th>
                <th className="py-3 px-2 text-left">Component</th>
                <th className="py-3 px-2 text-center">Qty</th>
                <th className="py-3 px-2 text-center">Modules</th>
              </tr>
            </thead>
            <tbody>
              {filterItems(modBoxes).map((item, index) => (
                <tr
                  key={index}
                  className={`${index !== modBoxes.length - 1 ? 'border-b border-gray-700' : ''}`}
                >
                  <td className="py-1 px-2 text-center">
                    <input
                      type="checkbox"
                      id={item.prefix}
                      onChange={() => saveSelection(item.prefix)}
                      className="form-checkbox h-4 w-4 text-blue-600"
                    />
                    <label htmlFor={item.prefix} className="sr-only">
                      {item.prefix}
                    </label>
                  </td>
                  <td className="py-1 px-2 cursor-pointer" onClick={() => togglePopup(item)}>
                    {item.prefix}
                  </td>

                  {popupItem && (
                    <div
                      className="fixed inset-0 flex items-center justify-center bg-slate-500 bg-opacity-10 p-10"
                      onClick={() => togglePopup(null)}
                    >
                      <div
                        className="relative bg-slate-700 border border-gray-400 rounded-lg shadow-md"
                        onClick={(e) => e.stopPropagation()}
                      >
                        {/* Popup Header with Different Background */}
                        <h2 className="text-white bg-gray-800 text-lg font-bold p-3 rounded-t-lg">
                          {popupItem.prefix || "No name available"}
                        </h2>

                        {/* Horizontal Line to Separate Header from Description */}
                        <hr className="border-gray-400" />

                        {/* Popup Description */}
                        <p className="text-white p-3">{popupItem.description || "No description available"}</p>
                        <p className="text-white px-3">Cost:{popupItem.qty || ""}</p>
                        <p className="text-white px-3 pl-5">
                        <span className="font-semibold text-white">{popupItem.componentQuantity || ""}x</span>
                        <span className="ml-2 text-white">{popupItem.component}</span>
                        </p>
                        <p className="text-white px-3 pb-3 pl-5">
                        <span className="font-semibold text-white">{popupItem.legendaryModule || ""}x</span>
                        <span className="ml-2 text-white">Modules</span>
                        </p>
                        <div className="flex flex-col items-center">
                          <p className="text-white text-xs pb-3 px-3 self-center">
                            Descriptions were taken from 
                            <a 
                              href="https://docs.google.com/spreadsheets/d/1WX8yc-r3p7H6SEYMaA8NfNFjeaUn3CtIWedChvPZzcg/"
                              target="_blank" 
                              className="text-blue-500 hover:text-blue-700" rel="noreferrer"
                            > Fallout 76 Legendary Mod Crafting Checklist</a>
                          </p>
                        </div>
                      </div>
                    </div>
                  )}

                  <td className="py-1 px-2">{item.component}</td>
                  <td className="py-1 px-2 text-center">{item.componentQuantity}</td>
                  <td className="py-1 px-2 text-center">{item.legendaryModule}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ModBoxChecklistComponent;
