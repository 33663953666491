import React from 'react';
import Checklist from '../components/Checklist';

const fasnachtItems = [
  { name: "Fasnacht Glowing Pig Mask", column: 1, dropRate: 0.238 },
  { name: "Fasnacht Glowing Unicorn Mask", column: 1, dropRate: 0.238 },
  { name: "Fasnacht Glowing Minotaur Mask", column: 1, dropRate: 0.238 },
  { name: "Fasnacht Glowing Alien Mask", column: 1, dropRate: 0.238 },
  { name: "Fasnacht Glowing Turkey Mask", column: 1, dropRate: 0.238 },
  { name: "Fasnacht Glowing Robot Mask", column: 1, dropRate: 0.238 },
  { name: "Fasnacht Glowing Honey Bee Mask", column: 1, dropRate: 0.238 },
  { name: "Fasnacht Glowing Blue Devil Mask", column: 1, dropRate: 0.238 },
  { name: "Fasnacht Glowing Scorchbeast Mask", column: 1, dropRate: 0.238 },
  { name: "Fasnacht Glowing Scorchbeast Queen Mask", column: 1, dropRate: 0.238 },
  { name: "Fasnacht: Raven Mask", column: 1, dropRate: 0.238 },
  { name: "Fasnacht: Deathclaw Mask", column: 1, dropRate: 0.238 },
  { name: "Fasnacht: Winter Man Mask", column: 1, dropRate: 0.238 },
  { name: "Fasnacht: Loon Mask", column: 1, dropRate: 0.238 },
  { name: "Father Winter Helmet (T-45, no trade)", column: 1, dropRate: 0.238 },
  { name: "Fasnacht: Brahmin Mask", column: 1, dropRate: 0.238 },
  { name: "Fasnacht: Demon Mask", column: 1, dropRate: 0.238 },
  { name: "Fasnacht: Buffoon Mask", column: 1, dropRate: 0.238 },
  { name: "Fasnacht: Hag Mask", column: 1, dropRate: 0.238 },
  { name: "Fasnacht: Fiend Mask", column: 1, dropRate: 0.238 },
  { name: "Fasnacht: Crazy Guy Mask", column: 1, dropRate: 0.238 },

  { name: "Fasnacht: Soldier Mask", column: 1, dropRate: 4.75 },
  { name: "Fasnacht: Toothy Man Mask", column: 1, dropRate: 4.75 },
  { name: "Fasnacht: Witch Mask", column: 1, dropRate: 4.75 },
  { name: "Fasnacht: Giant Mask", column: 1, dropRate: 4.75 },
  { name: "Fasnacht: Owl Mask", column: 1, dropRate: 4.75 },

  { name: "Plan: Motorized Butter Churn", column: 2, dropRate: 3.125 },
  { name: "Plan: Beeswax Candle", column: 2, dropRate: 3.125 },
  { name: "Plan: Herdsman's Bell - Fasnacht", column: 2, dropRate: 3.125 },
  { name: "Plan: Herdsman's Bell - Lincoln", column: 2, dropRate: 3.125 },
  { name: "Plan: Fasnacht Veggie Man Beer Stein", column: 2, dropRate: 3.125 },
  { name: "Plan: Helvetian Flower Display", column: 2, dropRate: 3.125 },
  { name: "Plan: Scorchbeast Queen Beer Stein", column: 2, dropRate: 3.125 },
  { name: "Plan: Herdsman's Bell Display Rack", column: 2, dropRate: 3.125 },
  { name: "Plan: Herdsman's Bell", column: 2, dropRate: 3.125 },
  { name: "Plan: Fasnacht Balloons 01", column: 2, dropRate: 3.125 },
  { name: "Plan: Fasnacht Balloons 02", column: 2, dropRate: 3.125 },
  { name: "Plan: Fasnacht Branch Garland 01", column: 2, dropRate: 3.125 },
  { name: "Plan: Fasnacht Branch Garland 02", column: 2, dropRate: 3.125 },
  { name: "Plan: Fasnacht Confetti Pile 01", column: 2, dropRate: 3.125 },
  { name: "Plan: Fasnacht Confetti Pile 02", column: 2, dropRate: 3.125 },
  { name: "Plan: Fasnacht Flag Pole 01", column: 2, dropRate: 3.125 },
  { name: "Plan: Fasnacht Flag Pole 02", column: 2, dropRate: 3.125 },
  { name: "Plan: Fasnacht Hanging Ribbons 01", column: 2, dropRate: 3.125 },
  { name: "Plan: Fasnacht Hanging Ribbons 02", column: 2, dropRate: 3.125 },
  { name: "Plan: Fasnacht Hanging Snowflakes 01", column: 2, dropRate: 3.125 },
  { name: "Plan: Fasnacht Hanging Snowflakes 02", column: 2, dropRate: 3.125 },
  { name: "Plan: Fasnacht Helvetia Garland 01", column: 2, dropRate: 3.125 },
  { name: "Plan: Fasnacht Helvetia Garland 02", column: 2, dropRate: 3.125 },
  { name: "Plan: Fasnacht Mounted Flag 01", column: 2, dropRate: 3.125 },
  { name: "Plan: Fasnacht Mounted Flag 02", column: 2, dropRate: 3.125 },
  { name: "Plan: Fasnacht Mounted Ribbons 01", column: 2, dropRate: 3.125 },
  { name: "Plan: Fasnacht Mounted Ribbons 02", column: 2, dropRate: 3.125 },
  { name: "Plan: Fasnacht Party Streamers 01", column: 2, dropRate: 3.125 },
  { name: "Plan: Fasnacht Party Streamers 02", column: 2, dropRate: 3.125 },
  { name: "Plan: Fasnacht Pole Lantern 01", column: 2, dropRate: 3.125 },
  { name: "Plan: Fasnacht Pole Lantern 02", column: 2, dropRate: 3.125 },
  { name: "Plan: Fasnacht Ribbons Pole 01", column: 2, dropRate: 3.125 },
  { name: "Plan: Fasnacht Ribbons Pole 02", column: 2, dropRate: 3.125 },
  { name: "Plan: Megasloth Pelt Rug", column: 2, dropRate: 3.125 },
  { name: "Plan: Mounted Mega Sloth", column: 2, dropRate: 3.125 },
  { name: "Plan: Old Man Winter Effigy", column: 2, dropRate: 3.125 },
  { name: "Recipe: Fasnacht Donut", column: 2, dropRate: 3.125 },
  { name: "Recipe: Fasnacht Sausage", column: 2, dropRate: 3.125 },

  { name: "Fasnacht Pig Mask", column: 1, dropRate: 7.91 },
  { name: "Fasnacht Unicorn Mask", column: 1, dropRate: 7.91 },
  { name: "Fasnacht Minotaur Mask", column: 1, dropRate: 7.91 },
  { name: "Fasnacht Alien Mask", column: 1, dropRate: 7.91 },
  { name: "Fasnacht Turkey Mask", column: 1, dropRate: 7.91 },
  { name: "Fasnacht Robot Mask", column: 1, dropRate: 7.91 },
  { name: "Fasnacht Honey Bee Mask", column: 1, dropRate: 7.91 },
  { name: "Fasnacht Blue Devil Mask", column: 1, dropRate: 7.91 },
  { name: "Fasnacht Scorchbeast Mask", column: 1, dropRate: 7.91 },
  { name: "Fasnacht Scorchbeast Queen Mask", column: 1, dropRate: 7.91 },
  { name: "Fasnacht: Jester Mask", column: 1, dropRate: 7.91 },
  { name: "Fasnacht: Sun Mask", column: 1, dropRate: 7.91 },
  { name: "Fasnacht: Skull Mask", column: 1, dropRate: 7.91 },
  { name: "Fasnacht Beret", column: 1, dropRate: 7.91 },
  { name: "Fasnacht: Goblin Mask", column: 1, dropRate: 7.91 },
];

// Sort by dropRate and then by name
const sortedItems = fasnachtItems.sort((a, b) => {
  if (a.dropRate === b.dropRate) {
    return a.name.localeCompare(b.name);
  }
  return a.dropRate - b.dropRate;
});

const FasnachtChecklist = () => {
  return (
    <div>
      <h1 className="text-center text-2xl font-bold mt-5">Fasnacht Event Checklist</h1>
      <Checklist items={sortedItems} backupFilename="fasnacht_checklist_backup.json" />
    </div>
  );
};

export default FasnachtChecklist;
