import React from 'react';
import config from '../config';

const ComingSoon = () => {
  return (
    <div className="min-h-screen bg-gray-900 text-white flex flex-col justify-center items-center p-4 sm:p-6 md:p-8">
      <h1 className="text-4xl font-bold mb-4">Coming Soon</h1>
      <p className="text-lg text-gray-400 mb-6">We're working hard to bring you this feature. Stay tuned!</p>
      <div className="bg-gray-800 p-6 rounded-lg shadow-md w-full max-w-md sm:max-w-lg md:max-w-xl">
        <p className="text-gray-300">
          Thank you for your patience. If you have any questions or need assistance, feel free to reach out to <a href={config.empasmProfile} className="text-blue-500 hover:text-blue-700 ml-1">@Empasm</a> on our
          <a href={config.discordUrl} className="text-blue-500 hover:text-blue-700 ml-1">Discord</a> in the meantime check out 
          <a href={config.twitchUrl} className="text-blue-500 hover:text-blue-700 ml-1">DeluqxGaming on Twitch</a>.
        </p>
      </div>
    </div>
  );
};

export default ComingSoon;
