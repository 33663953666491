import React, { useState, useRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import config from '../config';

const SideDrawer = ({ links }) => {
  const [isOpen, setIsOpen] = useState(false);
  const drawerRef = useRef();
  const location = useLocation();

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (drawerRef.current && !drawerRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <>
      <button onClick={toggleDrawer} className="md:hidden text-white focus:outline-none">
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"}></path>
        </svg>
      </button>
      <div className={`fixed inset-0 bg-gray-900 bg-opacity-75 z-50 transform ${isOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out`}>
        <div ref={drawerRef} className="flex flex-col w-64 h-full bg-gray-800 shadow-lg">
          <button onClick={toggleDrawer} className="self-end p-4 text-white focus:outline-none">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </button>
          <div className="text-white text-lg font-bold p-4 pt-0 pb-0">
            FO76 Utilities
          </div>
          <nav className="flex flex-col p-4 pt-0 space-y-2 flex-grow">
            {links.map((link, index) =>
              link.dropdown ? (
                <div key={index} className="relative group">
                  <button className="text-white font-medium py-2 px-4 rounded hover:bg-gray-700 transition-colors">{link.label}</button>
                  <div className="flex flex-col space-y-2 mt-2 pl-4">
                    {link.links.map((sublink, subIndex) => (
                      <Link
                        key={subIndex}
                        to={sublink.to}
                        className={`${
                          location.pathname === sublink.to
                            ? 'bg-gray-700 text-white'
                            : 'text-gray-300 hover:text-white hover:bg-gray-700'
                        } font-light py-1 px-2 rounded transition-colors`}
                        onClick={toggleDrawer}
                      >
                        - {sublink.label}
                      </Link>
                    ))}
                  </div>
                </div>
              ) : (
                <Link
                  key={index}
                  to={link.to}
                  className={`${
                    location.pathname === link.to
                      ? 'bg-gray-700 text-white'
                      : 'text-gray-300 hover:text-white hover:bg-gray-700'
                  } font-medium py-2 px-4 rounded transition-colors`}
                  onClick={toggleDrawer}
                >
                  {link.label}
                </Link>
              )
            )}
          </nav>
          <div className="flex flex-col items-center p-4">
            <div className="inline-flex rounded-md shadow-sm" role="group">
              <a
                href={config.twitchUrl}
                className="px-4 py-2 text-sm font-medium text-white border border-purple-500 rounded-s-lg hover:bg-purple-600 hover:shadow-lg focus:z-10 focus:ring-2 focus:ring-purple-600 transition-transform transform hover:-translate-y-1"
              >
                Twitch
              </a>
              <a
                href={config.discordUrl}
                className="px-4 py-2 text-sm font-medium text-white border border-blue-500 rounded-e-lg hover:bg-blue-600 hover:shadow-lg focus:z-10 focus:ring-2 focus:ring-blue-600 transition-transform transform hover:-translate-y-1"
              >
                Discord
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SideDrawer;
