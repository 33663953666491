import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const DropdownMenu = ({ label, links }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative">
      <button onClick={toggleDropdown} className="text-white hover:underline">
        {label}
      </button>
      <div className={`absolute left-0 mt-2 w-48 bg-gray-800 rounded-md shadow-lg ${isOpen ? 'block' : 'hidden'}`}>
        {links.map((link, index) => (
          <Link
            key={index}
            to={link.to}
            className="block px-4 py-2 text-white hover:bg-gray-700"
            onClick={() => setIsOpen(false)}
          >
            {link.label}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default DropdownMenu;
