import React from 'react';
import { Link } from 'react-router-dom';
import DropdownMenu from './DropdownMenu';
import SideDrawer from './SideDrawer';
import config from '../config';

const links = [
  { to: '/', label: 'Home' },
  {
    dropdown: true,
    label: 'Checklists',
    links: [
      { to: '/fasnacht', label: 'Fasnacht' },
      { to: '/holiday-scorched', label: 'Holiday Scorched' },
      { to: '/mothman-equinox', label: 'Mothman Equinox' },
      { to: '/treasure-hunter', label: 'Treasure Hunter' },
      { to: '/mod-box-checklist', label: 'Mod Boxes' },
    ],
  },
  { to: '/nuke-timers', label: 'Nuke Timer' },
];

const Navigation = () => {
  return (
    <nav className="bg-gray-900 dark:bg-gray-800 p-4 shadow-md fixed top-0 left-0 w-full z-50">
      <div className="container mx-auto flex justify-between items-center">
        <div className="text-white text-lg font-bold">FO76 Utilities</div>
        <div className="hidden md:flex space-x-8 justify-center flex-grow">
          {links.map((link, index) =>
            link.dropdown ? (
              <DropdownMenu key={index} label={link.label} links={link.links} />
            ) : (
              <Link key={index} to={link.to} className="text-white hover:underline">
                {link.label}
              </Link>
            )
          )}
        </div>
        <div className="hidden md:flex">
          <div className="inline-flex rounded-md shadow-sm" role="group">
            <a
              href={config.twitchUrl}
              className="px-4 py-2 text-sm font-medium text-white border border-purple-500 rounded-s-lg hover:bg-purple-600 hover:shadow-lg focus:z-10 focus:ring-2 focus:ring-purple-600 transition-transform transform hover:-translate-y-1"
            >
              Twitch
            </a>
            <a
              href={config.discordUrl}
              className="px-4 py-2 text-sm font-medium text-white border border-blue-500 rounded-e-lg hover:bg-blue-600 hover:shadow-lg focus:z-10 focus:ring-2 focus:ring-blue-600 transition-transform transform hover:-translate-y-1"
            >
              Discord
            </a>
          </div>
        </div>
        <SideDrawer links={links} />
      </div>
    </nav>
  );
};

export default Navigation;
