// src/components/Timer.js
import React, { useEffect, useState, useCallback } from 'react';

const Timer = ({ label, launchTime, onExpire }) => {
  const calculateTimeLeft = useCallback(() => {
    const now = new Date();
    const launchDate = new Date(launchTime);
    const difference = launchDate.getTime() + 3 * 60 * 60 * 1000 - now.getTime(); // 3 hours const difference = launchDate.getTime() + 3 * 60 * 60 * 1000 - now.getTime();
    if (difference <= 0) {
      onExpire(label);
      return null;
    }

    const hours = Math.floor(difference / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    return { hours, minutes, seconds };
  }, [launchTime, label, onExpire]);

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [calculateTimeLeft]);

  if (!timeLeft) {
    return <div className="text-red-500">{label} Timer expired</div>;
  }

  return (
    <div>
      <div className="font-bold text-lg mb-2">{label} Timer</div>
      <span className="font-mono text-lg">{timeLeft.hours}:{timeLeft.minutes}:{timeLeft.seconds}</span>
    </div>
  );
};

export default Timer;
