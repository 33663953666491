import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import config from '../config';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '@fortawesome/fontawesome-free/js/all.min.js';
import 'react-tooltip/dist/react-tooltip.css';

const chemList = [
  'Addictol', 'Antibiotics', 'Berry Mentats', 'Blood Pack', 'Buffout', 'Bufftats', 'Calmex', 'Daddy-O',
  'Day Tripper', 'Disease Cure', 'Formula P', 'Fury', 'Glowing blood', 'Glowing blood pack', 'Grape Mentats',
  'High-radiation Fluids', 'Insect Repellent', 'Irradiated blood pack', 'Med-X', 'Mentats', 'Nuclear Don\'s Custom Chem Blend',
  'Orange Mentats', 'Overdrive', 'Psycho', 'Psychobuff', 'Psychotats', 'Rad-X', 'Rad-X: diluted', 'Radaway', 'Radaway: diluted',
  'RadShield', 'Skeeto Spit', 'Super Chem Mk I', 'X-Cell'
];

const curatorList = [
  'Backwoodsman 6', 'Live & Love 3', 'Leader Bobblehead', 'Intelligence Bobblehead'
];

const timers = [
  // Add your timer objects here
];

const tones = [
  // Add your tone objects here
];

const XPBuffTimers = () => {
  const [intervals, setIntervals] = useState({});
  const [chemFiendMultiplier, setChemFiendMultiplier] = useState(1);
  const [curatorMultiplier, setCuratorMultiplier] = useState(1);
  const [currentAudio, setCurrentAudio] = useState(null);
  const [disableFlashing, setDisableFlashing] = useState(false);
  const [chemFiendLevel, setChemFiendLevel] = useState('none');
  const [curatorLevel, setCuratorLevel] = useState('none');
  const [volume, setVolume] = useState(0.5);
  const [isSettingsVisible, setIsSettingsVisible] = useState(true);

  useEffect(() => {
    const storedDisableFlashing = localStorage.getItem('disableFlashing');
    setDisableFlashing(storedDisableFlashing === 'true');

    const storedChemFiendLevel = localStorage.getItem('chemFiendLevel') || 'none';
    setChemFiendLevel(storedChemFiendLevel);
    updateChemFiendMultiplier(storedChemFiendLevel);

    const storedCuratorLevel = localStorage.getItem('curatorLevel') || 'none';
    setCuratorLevel(storedCuratorLevel);
    updateCuratorMultiplier(storedCuratorLevel);
  }, []);

  const updateChemFiendMultiplier = (level) => {
    if (level === '1') {
      setChemFiendMultiplier(1.3);
    } else if (level === '2') {
      setChemFiendMultiplier(1.6);
    } else if (level === '3') {
      setChemFiendMultiplier(2);
    } else {
      setChemFiendMultiplier(1);
    }
  };

  const updateCuratorMultiplier = (level) => {
    if (level === '1') {
      setCuratorMultiplier(2);
    } else {
      setCuratorMultiplier(1);
    }
  };

  const saveTimerState = (id, startTime, duration, toneFile) => {
    const state = { startTime, duration, toneFile };
    localStorage.setItem(`timerState-${id}`, JSON.stringify(state));
  };

  const getTimerState = (id) => {
    const state = localStorage.getItem(`timerState-${id}`);
    return state ? JSON.parse(state) : null;
  };

  const removeTimerState = (id) => {
    localStorage.removeItem(`timerState-${id}`);
  };

  const startTimer = (duration, display, toneFile, button, dropdown, previewButton, listItem, disableFlashing, volume) => {
    const originalDuration = duration;
    const timerName = listItem.querySelector('.font-bold').textContent;
    if (chemList.includes(timerName)) {
      duration *= chemFiendMultiplier;
    }
    if (curatorList.includes(timerName)) {
      duration *= curatorMultiplier;
    }
    const startTime = Date.now();
    const endTime = startTime + duration * 1000;
    saveTimerState(listItem.id, startTime, originalDuration, toneFile);

    button.style.display = 'none';
    dropdown.style.display = 'none';
    previewButton.style.display = 'none';
    listItem.querySelector('.time-remaining').style.display = 'block';
    listItem.querySelector('.reset-timer').style.display = 'block';

    setIntervals((prevIntervals) => ({
      ...prevIntervals,
      [listItem.id]: setInterval(() => {
        const currentTime = Date.now();
        let remainingTime = Math.max(0, endTime - currentTime) / 1000;
        let hours = parseInt(remainingTime / 3600, 10);
        let minutes = parseInt((remainingTime % 3600) / 60, 10);
        let seconds = parseInt((remainingTime % 60), 10);

        hours = hours < 10 ? "0" + hours : hours;
        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        if (hours === "00") {
          display.textContent = minutes + ":" + seconds;
        } else {
          display.textContent = hours + ":" + minutes + ":" + seconds;
        }

        if (remainingTime <= 0) {
          clearInterval(intervals[listItem.id]);
          setIntervals((prevIntervals) => {
            const { [listItem.id]: _, ...rest } = prevIntervals;
            return rest;
          });
          playTone(toneFile, button, dropdown, previewButton, listItem, disableFlashing, volume);
          removeTimerState(listItem.id);
        }
      }, 1000),
    }));
  };

  const playTone = (toneFile, button, dropdown, previewButton, listItem, disableFlashing, volume = 0.5) => {
    if (currentAudio) {
      currentAudio.pause();
      currentAudio.currentTime = 0;
    }
    const audio = new Audio(toneFile);
    audio.volume = volume;
    audio.play();
    setCurrentAudio(audio);

    if (!disableFlashing) {
      listItem.classList.add('flash');
    }
    audio.onended = () => {
      setCurrentAudio(null);
    };
  };

  const previewSound = (toneFile, volume = 0.5) => {
    if (currentAudio) {
      currentAudio.pause();
      currentAudio.currentTime = 0;
    }
    const audio = new Audio(toneFile);
    audio.volume = volume;
    audio.play();
    setCurrentAudio(audio);

    audio.onended = () => {
      setCurrentAudio(null);
    };
  };

  const resetTimer = (listItem, button, dropdown, previewButton) => {
    clearInterval(intervals[listItem.id]);
    setIntervals((prevIntervals) => {
      const { [listItem.id]: _, ...rest } = prevIntervals;
      return rest;
    });
    const timeElement = listItem.querySelector('.time-remaining .time');
    if (timeElement) {
      timeElement.textContent = '00:00';
    }
    listItem.classList.remove('flash');
    listItem.querySelector('.time-remaining').style.display = 'none';
    listItem.querySelector('.reset-timer').style.display = 'none';
    button.style.display = 'block';
    dropdown.style.display = 'block';
    previewButton.style.display = 'block';
    removeTimerState(listItem.id);

    if (currentAudio) {
      currentAudio.pause();
      currentAudio.currentTime = 0;
      setCurrentAudio(null);
    }
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white">
      <div className="container mx-auto p-4">
        <button
          className="bg-blue-500 text-white py-2 px-4 rounded mb-4"
          onClick={() => setIsSettingsVisible(!isSettingsVisible)}
        >
          {isSettingsVisible ? 'Hide Settings' : 'Show Settings'}
        </button>
        {isSettingsVisible && (
          <div className="space-y-4">
            <div className="card bg-gray-800 p-4 mb-4">
              <h5 className="text-lg font-bold">Photosensitive Settings</h5>
              <div className="form-check form-switch mt-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="disable-flashing"
                  checked={disableFlashing}
                  onChange={(e) => setDisableFlashing(e.target.checked)}
                />
                <label className="form-check-label" htmlFor="disable-flashing">
                  Disable Flashing
                </label>
              </div>
            </div>
            <div className="card bg-gray-800 p-4 mb-4">
              <h5 className="text-lg font-bold">Chem Fiend Perk</h5>
              <div className="flex space-x-4 mt-2">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="chem-fiend"
                    value="1"
                    id="chem-fiend-1"
                    checked={chemFiendLevel === '1'}
                    onChange={() => setChemFiendLevel('1')}
                  />
                  <label className="form-check-label" htmlFor="chem-fiend-1">
                    1 Star
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="chem-fiend"
                    value="2"
                    id="chem-fiend-2"
                    checked={chemFiendLevel === '2'}
                    onChange={() => setChemFiendLevel('2')}
                  />
                  <label className="form-check-label" htmlFor="chem-fiend-2">
                    2 Stars
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="chem-fiend"
                    value="3"
                    id="chem-fiend-3"
                    checked={chemFiendLevel === '3'}
                    onChange={() => setChemFiendLevel('3')}
                  />
                  <label className="form-check-label" htmlFor="chem-fiend-3">
                    3 Stars
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="chem-fiend"
                    value="none"
                    id="chem-fiend-none"
                    checked={chemFiendLevel === 'none'}
                    onChange={() => setChemFiendLevel('none')}
                  />
                  <label className="form-check-label" htmlFor="chem-fiend-none">
                    None
                  </label>
                </div>
              </div>
            </div>
            <div className="card bg-gray-800 p-4 mb-4">
              <h5 className="text-lg font-bold">Curator Perk</h5>
              <div className="flex space-x-4 mt-2">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="curator"
                    value="1"
                    id="curator-1"
                    checked={curatorLevel === '1'}
                    onChange={() => setCuratorLevel('1')}
                  />
                  <label className="form-check-label" htmlFor="curator-1">
                    Enabled
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="curator"
                    value="none"
                    id="curator-none"
                    checked={curatorLevel === 'none'}
                    onChange={() => setCuratorLevel('none')}
                  />
                  <label className="form-check-label" htmlFor="curator-none">
                    Disabled
                  </label>
                </div>
              </div>
            </div>
            <div className="card bg-gray-800 p-4 mb-4">
              <h5 className="text-lg font-bold">Volume Control</h5>
              <div className="flex flex-col items-center mt-2">
                <span id="volume-percentage" className="font-bold mb-2">
                  {Math.round(volume * 100)}%
                </span>
                <input
                  type="range"
                  className="form-range w-full"
                  id="volume-slider"
                  min="0"
                  max="100"
                  value={volume * 100}
                  onChange={(e) => setVolume(e.target.value / 100)}
                />
              </div>
            </div>
          </div>
        )}
        <div className="bg-gray-800 p-4 mb-4">
          <h6 className="text-lg font-bold">Use all of these</h6>
          <hr className="border-gray-700 mb-4" />
          <ul className="list-group mb-5">
            {timers.filter(timer => timer.group === 'AlwaysActive').map((timer, index) => (
              <li className="list-group-item bg-gray-700 mb-2" key={index} id={`timer-${index}`}>
                <div className="flex flex-wrap">
                  <div className="flex-shrink-0 w-52 pr-4">
                    <div className="font-bold">{timer.name}</div>
                    <span className="text-gray-400">
                      {timer.duration >= 60 ? `${Math.floor(timer.duration / 60)} Minutes` : `${timer.duration} Seconds`}
                    </span>
                  </div>
                  <div className="flex-grow flex items-center mt-1 px-4">
                    <select className="tone-select form-control me-2 bg-gray-600 text-white" id={`tone-select-${timer.group}-${index}`}>
                      {tones.map((tone, toneIndex) => (
                        <option key={toneIndex} value={tone.file}>{tone.name}</option>
                      ))}
                    </select>
                    <button className="btn btn-secondary preview-sound bg-gray-500 text-white">Preview</button>
                    <div className="time-remaining" style={{ display: 'none' }}>
                      <strong>Time Remaining:</strong> <span className="time">00:00</span>
                    </div>
                  </div>
                  <div className="flex-shrink-0 flex justify-end items-center mt-1 pl-4 min-w-[250px]">
                    {timer.herbivore && (
                      <div className="relative group">
                        <i className="fas fa-leaf fa-xl me-1 text-green-600" data-tip data-for={`tooltip-herbivore-${index}`}></i>
                        <Tooltip id={`tooltip-herbivore-${index}`} place="top" effect="solid">Herbivore</Tooltip>
                      </div>
                    )}
                    {timer.carnivore && (
                      <div className="relative group">
                        <i className="fas fa-drumstick-bite fa-xl me-1 text-red-600" data-tip data-for={`tooltip-carnivore-${index}`}></i>
                        <Tooltip id={`tooltip-carnivore-${index}`} place="top" effect="solid">Carnivore</Tooltip>
                      </div>
                    )}
                    {timer.category === 'Magazine' && (
                      <div className="relative group">
                        <i className="fas fa-book-open fa-xl text-yellow-400" data-tip data-for={`tooltip-magazine-${index}`}></i>
                        <Tooltip id={`tooltip-magazine-${index}`} place="top" effect="solid">Magazine</Tooltip>
                      </div>
                    )}
                    {timer.category === 'Direct XP Boost' && (
                      <div className="relative group">
                        <i className="fas fa-star fa-xl text-purple-400" data-tip data-for={`tooltip-direct-xp-${index}`}></i>
                        <Tooltip id={`tooltip-direct-xp-${index}`} place="top" effect="solid">Direct XP Boost</Tooltip>
                      </div>
                    )}
                    {timer.category === 'Intelligence Boost' && (
                      <div className="relative group">
                        <i className="fas fa-brain fa-xl text-blue-400" data-tip data-for={`tooltip-intelligence-${index}`}></i>
                        <Tooltip id={`tooltip-intelligence-${index}`} place="top" effect="solid">Intelligence Boost</Tooltip>
                      </div>
                    )}
                    {timer.category === 'Camp Buff' && (
                      <div className="relative group">
                        <i className="fas fa-home fa-xl me-1 text-cyan-400" data-tip data-for={`tooltip-camp-buff-${index}`}></i>
                        <Tooltip id={`tooltip-camp-buff-${index}`} place="top" effect="solid">Camp Buff</Tooltip>
                      </div>
                    )}
                    <button className="btn btn-primary ms-2 start-timer bg-blue-500 text-white" data-duration={timer.duration}>Start Timer</button>
                    <button className="btn btn-danger ms-2 reset-timer bg-red-500 text-white" style={{ display: 'none' }}>Reset Timer</button>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="bg-gray-800 p-4 mb-4">
          <h6 className="text-lg font-bold">Pick one from each group</h6>
          <hr className="border-gray-700 mb-4" />
          {Array.from(new Set(timers.filter(timer => timer.group !== 'AlwaysActive').map(timer => timer.group))).map((group, groupIndex) => (
            <div className="mt-3" key={groupIndex}>
              <div className="list-group mb-1">
                {timers.filter(timer => timer.group === group).map((timer, index) => (
                  <li className="list-group-item bg-gray-700 mb-2" key={index} id={`timer-${group}-${index}`}>
                    <div className="flex flex-wrap">
                      <div className="flex-shrink-0 w-52 pr-4">
                        <div className="font-bold">{timer.name}</div>
                        <span className="text-gray-400">
                          {timer.duration >= 60 ? `${Math.floor(timer.duration / 60)} Minutes` : `${timer.duration} Seconds`}
                        </span>
                      </div>
                      <div className="flex-grow flex items-center mt-1 px-4">
                        <select className="tone-select form-control me-2 bg-gray-600 text-white" id={`tone-select-${timer.group}-${index}`}>
                          {tones.map((tone, toneIndex) => (
                            <option key={toneIndex} value={tone.file}>{tone.name}</option>
                          ))}
                        </select>
                        <button className="btn btn-secondary preview-sound bg-gray-500 text-white">Preview</button>
                        <div className="time-remaining" style={{ display: 'none' }}>
                          <strong>Time Remaining:</strong> <span className="time">00:00</span>
                        </div>
                      </div>
                      <div className="flex-shrink-0 flex justify-end items-center mt-1 pl-4 min-w-[250px]">
                        {timer.herbivore && (
                          <div className="relative group">
                            <i className="fas fa-leaf fa-xl me-1 text-green-600" data-tip data-for={`tooltip-herbivore-${group}-${index}`}></i>
                            <Tooltip id={`tooltip-herbivore-${group}-${index}`} place="top" effect="solid">Herbivore</Tooltip>
                          </div>
                        )}
                        {timer.carnivore && (
                          <div className="relative group">
                            <i className="fas fa-drumstick-bite fa-xl me-1 text-red-600" data-tip data-for={`tooltip-carnivore-${group}-${index}`}></i>
                            <Tooltip id={`tooltip-carnivore-${group}-${index}`} place="top" effect="solid">Carnivore</Tooltip>
                          </div>
                        )}
                        {timer.category === 'Magazine' && (
                          <div className="relative group">
                            <i className="fas fa-book-open fa-xl text-yellow-400" data-tip data-for={`tooltip-magazine-${group}-${index}`}></i>
                            <Tooltip id={`tooltip-magazine-${group}-${index}`} place="top" effect="solid">Magazine</Tooltip>
                          </div>
                        )}
                        {timer.category === 'Direct XP Boost' && (
                          <div className="relative group">
                            <i className="fas fa-star fa-xl text-purple-400" data-tip data-for={`tooltip-direct-xp-${group}-${index}`}></i>
                            <Tooltip id={`tooltip-direct-xp-${group}-${index}`} place="top" effect="solid">Direct XP Boost</Tooltip>
                          </div>
                        )}
                        {timer.category === 'Intelligence Boost' && (
                          <div className="relative group">
                            <i className="fas fa-brain fa-xl text-blue-400" data-tip data-for={`tooltip-intelligence-${group}-${index}`}></i>
                            <Tooltip id={`tooltip-intelligence-${group}-${index}`} place="top" effect="solid">Intelligence Boost</Tooltip>
                          </div>
                        )}
                        {timer.category === 'Camp Buff' && (
                          <div className="relative group">
                            <i className="fas fa-home fa-xl me-1 text-cyan-400" data-tip data-for={`tooltip-camp-buff-${group}-${index}`}></i>
                            <Tooltip id={`tooltip-camp-buff-${group}-${index}`} place="top" effect="solid">Camp Buff</Tooltip>
                          </div>
                        )}
                        <button className="btn btn-primary ms-2 start-timer bg-blue-500 text-white" data-duration={timer.duration}>Start Timer</button>
                        <button className="btn btn-danger ms-2 reset-timer bg-red-500 text-white" style={{ display: 'none' }}>Reset Timer</button>
                      </div>
                    </div>
                  </li>
                ))}
              </div>
            </div>
          ))}
        </div>
        <div className="bg-gray-800 p-4 mb-4">
          <h4 className="text-lg font-bold">Check Out My Favorite Streamer!</h4>
          <p className="text-gray-400">
            I'm not the streamer myself, but if you're looking for great gaming content, be sure to check out Deluqx's channels! Follow the links below to connect on Twitch and Discord.
          </p>
          <ul className="list-none">
            <li>
              <a href="https://discord.com/invite/nRShDhzaU7" target="_blank" rel="noopener noreferrer" className="btn bg-blue-500 text-white rounded hover:bg-blue-600">Join Discord</a>
            </li>
            <li>
              <a href="https://www.twitch.tv/deluqxgaming" target="_blank" rel="noopener noreferrer" className="btn bg-blue-500 text-white rounded hover:bg-blue-600">Watch on Twitch</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default XPBuffTimers;
